import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%',
		marginLeft: 0,
		marginBottom: 0,
		marginTop: 16
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

const MaterialUISelect = (props) => {
	const classes = useStyles();

	return (
		<FormControl className={classes.formControl}>
			<InputLabel htmlFor={props.name}>{props.label}</InputLabel>
			<Select
				native
				value={props.value}
				onChange={props.onChange}
				error={props.error}
				disabled={props.disabled}
				inputProps={{ name: props.name, id: props.name, disabled: props.readOnly }}
			>
				{!props.value && <option aria-label="None" value={null} />}
				{props.options.map((option, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<option value={option.value} key={`${option.value}-${index}`}>
						{option.label}
					</option>
				))}
			</Select>
		</FormControl>
	);
};

export default memo(MaterialUISelect);
