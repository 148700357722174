import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from "./notification";
import errorReducer from "./error";

const store = configureStore({
	reducer: {
		notification: notificationReducer,
		error: errorReducer
	}
});

export default store;
