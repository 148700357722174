import React, { useState, useEffect, memo, useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import DocumentSection from './DocumentSection';
import xhr from '../../utils/xhr';
import { showNotification } from '../../redux/notification';

const FREE_FORM_DOCUMENT_TYPES = [];
const NO_EXPIRATION_DATE_DOCUMENTS = [];

const initialFormData = {
	list_a_primary_document_type: '',
	list_a_primary_document_issuing_authority: '',
	list_a_primary_document_number: '',
	list_a_primary_document_expiration_date: '',
	list_a_primary_attachment: null,
	list_a_primary_attachment_id: null,

	list_a_secondary_document_type: '',
	list_a_secondary_document_issuing_authority: '',
	list_a_secondary_document_number: '',
	list_a_secondary_document_expiration_date: '',
	list_a_secondary_attachment: null,
	list_a_secondary_attachment_id: null,

	list_a_tertiary_document_type: '',
	list_a_tertiary_document_issuing_authority: '',
	list_a_tertiary_document_number: '',
	list_a_tertiary_document_expiration_date: '',
	list_a_tertiary_attachment: null,
	list_a_tertiary_attachment_id: null
};

function formDataReducer(state, action) {
	switch (action.type) {
		case 'UPDATE':
			// eslint-disable-next-line no-case-declarations
			const { value } = action;
			return { ...state, ...value };
		case 'RESET_DATA':
			return { ...state, ...initialFormData };
		default:
			return state;
	}
}

const SectionA = (props) => {
	const reduxDispatch = useDispatch();

	const [formData, dispatch] = useReducer(formDataReducer, initialFormData);
	const [errors, setErrors] = useState({ listA1: {}, listA2: {}, listA3: {} });
	const [primaryDocumentOptions, setPrimaryDocumentOptions] = useState([]);
	const [secondaryDocumentOptions, setSecondaryDocumentOptions] = useState([]);
	const [tertiaryDocumentOptions, setTertiaryDocumentOptions] = useState([]);
	const [primaryAuthorityOptions, setPrimaryAuthorityOptions] = useState([]);
	const [secondaryAuthorityOptions, setSecondaryAuthorityOptions] = useState([]);
	const [tertiaryAuthorityOptions, setTertiaryAuthorityOptions] = useState([]);

	useEffect(() => {
		setPrimaryDocumentOptions(
			props.listA.map((item) => ({
				label: item.document_name,
				value: item.document_code,
				is_issuing_authority_required: item.is_issuing_authority_required,
				is_document_number_required: item.is_document_number_required,
				is_expiration_date_required: item.is_expiration_date_required
			}))
		);
	}, [props.listA]);

	const updateFormData = useCallback(
		(newData) => {
			if (typeof newData === 'object' && newData !== null) {
				// eslint-disable-next-line no-restricted-syntax
				for (const key of Object.keys(newData)) {
					// eslint-disable-next-line no-param-reassign
					newData[key] =
						typeof newData[key] === 'string' || newData[key] instanceof String ? newData[key].trim() : newData[key];
				}
			}
			dispatch({ type: 'UPDATE', value: newData });
		},
		[dispatch]
	);

	const selectPrimaryDocumentType = (type) => {
		updateFormData({ list_a_primary_document_type: type });

		const document = props.listA.find((doc) => doc.document_code === type);
		setPrimaryAuthorityOptions(
			(document.issuing_authority || []).map((item) => ({ label: item.name, value: item.code }))
		);
		setSecondaryDocumentOptions(
			(document.secondary_documents || []).map((item) => ({
				label: item.document_name,
				value: item.document_code,
				is_issuing_authority_required: item.is_issuing_authority_required,
				is_document_number_required: item.is_document_number_required,
				is_expiration_date_required: item.is_expiration_date_required
			}))
		);
		setSecondaryAuthorityOptions(
			(document.secondary_issuing_authority || []).map((item) => ({ label: item.name, value: item.code }))
		);
		setTertiaryDocumentOptions(
			(document.tertiary_documents || []).map((item) => ({
				label: item.document_name,
				value: item.document_code,
				is_issuing_authority_required: item.is_issuing_authority_required,
				is_document_number_required: item.is_document_number_required,
				is_expiration_date_required: item.is_expiration_date_required
			}))
		);
		setTertiaryAuthorityOptions(
			(document.tertiary_issuing_authority || []).map((item) => ({ label: item.name, value: item.code }))
		);
	};

	const processResponseErrors = (responseErrors) => {
		const message = responseErrors[0] && responseErrors[0].message;
		reduxDispatch(showNotification('error', message || 'Failed to submit I9 data. Please try again!'));

		const _errors = { listA1: {}, listA2: {}, listA3: {} };
		// eslint-disable-next-line no-restricted-syntax
		for (const error of responseErrors) {
			if (error.field) {
				const errorFieldKey = error.field.slice(error.field.lastIndexOf('_') + 1);
				if (error.field.startsWith('list_a_primary_')) {
					_errors.listA1[errorFieldKey] = true;
				} else if (error.field.startsWith('list_a_secondary_')) {
					_errors.listA2[errorFieldKey] = true;
				} else if (error.field.startsWith('list_a_tertiary')) {
					_errors.listA3[errorFieldKey] = true;
				}
			}
		}
		setErrors(_errors);
	};

	const onSectionA1Review = async (reviewed) => {
		props.setSectionsReviewed({ sectionA1: reviewed });
		try {
			if (reviewed) {
				props.showLoadingSpinner(true);
				await xhr.request('POST', `/i9/section-2/list-a/primary`, props.token, formData);
				setErrors({ ...errors, listA1: {} });
				props.showLoadingSpinner(false);
				return true;
			}
			return false;
		} catch (error) {
			processResponseErrors(error.response.data.errors);
			props.showLoadingSpinner(false);
			return false;
		}
	};

	const onSectionA2Review = async (reviewed) => {
		props.setSectionsReviewed({ sectionA2: reviewed });
		if (reviewed) {
			try {
				props.showLoadingSpinner(true);
				await xhr.request('POST', `/i9/section-2/list-a/secondary`, props.token, formData);
				props.showLoadingSpinner(false);
				return true;
			} catch (error) {
				processResponseErrors(error.response.data.errors);
				props.showLoadingSpinner(false);
				return false;
			}
		}
		return false;
	};

	const onSectionA3Review = async (reviewed) => {
		props.setSectionsReviewed({ sectionA3: reviewed });
		if (reviewed) {
			try {
				props.showLoadingSpinner(true);
				await xhr.request('POST', `/i9/section-2/list-a/tertiary`, props.token, formData);
				props.showLoadingSpinner(false);
				return true;
			} catch (error) {
				processResponseErrors(error.response.data.errors);
				props.showLoadingSpinner(false);
				return false;
			}
		}
		return false;
	};

	return [
		<DocumentSection
			key="first"
			sectionId="first"
			title={`Select ${props.caregiver_first_name}'s document from List A`}
			documentOptions={primaryDocumentOptions}
			authorityOptions={primaryAuthorityOptions}
			prefilledDocument={props.listAPrefilledDocuments.primary}
			freeFormDocumentTypes={FREE_FORM_DOCUMENT_TYPES}
			noExpirationDateDocuments={NO_EXPIRATION_DATE_DOCUMENTS}
			onSelectDocument={selectPrimaryDocumentType}
			onSelectAuthority={(value) => updateFormData({ list_a_primary_document_issuing_authority: value })}
			onChangeNumber={(value) => updateFormData({ list_a_primary_document_number: value })}
			onChangeDate={(value) => updateFormData({ list_a_primary_document_expiration_date: value })}
			onChangeAttachment={(value) => updateFormData({ list_a_primary_attachment: value })}
			onChangeAttachmentId={(id, hmac) =>
				updateFormData({ list_a_primary_attachment_id: id, list_a_primary_attachment_hmac: hmac })
			}
			onSetSectionReviewed={onSectionA1Review}
			errors={errors.listA1}
		/>,
		secondaryDocumentOptions.length > 0 && (
			<DocumentSection
				key="second"
				sectionId="second"
				title={`Select ${props.caregiver_first_name}'s additional document from List A`}
				documentOptions={secondaryDocumentOptions}
				authorityOptions={secondaryAuthorityOptions}
				prefilledDocument={props.listAPrefilledDocuments.secondary}
				freeFormDocumentTypes={FREE_FORM_DOCUMENT_TYPES}
				noExpirationDateDocuments={NO_EXPIRATION_DATE_DOCUMENTS}
				onSelectDocument={(value) => updateFormData({ list_a_secondary_document_type: value })}
				onSelectAuthority={(value) => updateFormData({ list_a_secondary_document_issuing_authority: value })}
				onChangeNumber={(value) => updateFormData({ list_a_secondary_document_number: value })}
				onChangeDate={(value) => updateFormData({ list_a_secondary_document_expiration_date: value })}
				onChangeAttachment={(value) => updateFormData({ list_a_secondary_attachment: value })}
				onChangeAttachmentId={(id, hmac) =>
					updateFormData({ list_a_secondary_attachment_id: id, list_a_secondary_attachment_hmac: hmac })
				}
				onSetSectionReviewed={onSectionA2Review}
				errors={errors.listA2}
			/>
		),
		tertiaryDocumentOptions.length > 0 && (
			<DocumentSection
				key="third"
				sectionId="third"
				title={`Select ${props.caregiver_first_name}'s additional document from List A`}
				documentOptions={tertiaryDocumentOptions}
				authorityOptions={tertiaryAuthorityOptions}
				prefilledDocument={props.listAPrefilledDocuments.tertiary}
				freeFormDocumentTypes={FREE_FORM_DOCUMENT_TYPES}
				noExpirationDateDocuments={NO_EXPIRATION_DATE_DOCUMENTS}
				onSelectDocument={(value) => updateFormData({ list_a_tertiary_document_type: value })}
				onSelectAuthority={(value) => updateFormData({ list_a_tertiary_document_issuing_authority: value })}
				onChangeNumber={(value) => updateFormData({ list_a_tertiary_document_number: value })}
				onChangeDate={(value) => updateFormData({ list_a_tertiary_document_expiration_date: value })}
				onChangeAttachment={(value) => updateFormData({ list_a_tertiary_attachment: value })}
				onChangeAttachmentId={(id, hmac) =>
					updateFormData({ list_a_tertiary_attachment_id: id, list_a_tertiary_attachment_hmac: hmac })
				}
				onSetSectionReviewed={onSectionA3Review}
				errors={errors.listA3}
			/>
		)
	];
};

export default memo(SectionA);
