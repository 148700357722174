import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';

import xhr from '../../utils/xhr';
import { showNotification } from '../../redux/notification';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import Button from '../../components/buttons/Button';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import SuccessModal from '../../components/modals/SuccessModal';
import SectionA from './SectionA';
import SectionBC from './SectionBC';

import './Section2.css';

const textFieldDefaults = {
	type: 'text',
	inputProps: { style: { fontSize: 14, color: '#000' } },
	InputLabelProps: { style: { fontSize: 14, color: '#717579' } },
	autoComplete: 'off'
};

const readOnlyTextFieldDefaults = {
	type: 'text',
	inputProps: { style: { fontSize: 14, color: '#000' }, readOnly: true },
	InputLabelProps: { style: { fontSize: 14, color: '#717579' } },
	autoComplete: 'off'
};

/* data model for the POST payload */
const initialFormData = {
	representative_first_name: '',
	representative_last_name: '',
	representative_title: '',
	representative_city_name: '',
	representative_street_address: '',
	representative_apartment_number: '',
	representative_zip_code: '',
	representative_state: '',
	caregiver_first_name: '',
	caregiver_last_name: ''
};

const defaultSectionsReviewedState = {
	sectionA1: true,
	sectionA2: true,
	sectionA3: true,
	sectionB: true,
	sectionC: true
};

const Section2 = (props) => {
	const { token } = props.match.params;

	const dispatch = useDispatch();

	const [formData, setFormData] = useState(initialFormData);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [selectedList, setSelectedList] = useState('A');
	const [fetchedListData, setFetchedListData] = useState({
		list_a: [],
		list_b: [],
		list_c: [],
		listAPrefilledDocuments: { primary: null, secondary: null, tertiary: null },
		listBPrefilledDocument: null,
		listCPrefilledDocument: null
	});
	const [sectionsReviewed, setSectionsReviewed] = useState(defaultSectionsReviewedState);
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [errors, setErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);

	const getMappedData = (data, fieldKeyPrefix) => ({
		type: data[`${fieldKeyPrefix}_DOCUMENT_TYPE`],
		authority: data[`${fieldKeyPrefix}_DOCUMENT_ISSUING_AUTHORITY`],
		number: data[`${fieldKeyPrefix}_DOCUMENT_NUMBER`],
		date: data[`${fieldKeyPrefix}_DOCUMENT_EXPIRATION_DATE`],
		attachmentIsPrefilled: data[`${fieldKeyPrefix}_DOCUMENT_ATTACHMENT`],
		attachmentHmac: data[`${fieldKeyPrefix}_DOCUMENT_ATTACHMENT_HMAC`]
	});

	const setDefaultData = useCallback((data) => {
		const { employer, cgAuthRepDetails, documentLists, selectedDocuments } = data;

		setFetchedListData({
			list_a: documentLists.listADocuments,
			list_b: documentLists.listBDocuments,
			list_c: documentLists.listCDocuments,
			listAPrefilledDocuments: {
				primary: getMappedData(selectedDocuments.listADocumentFields.primary, 'LIST_A_PRIMARY'),
				secondary: getMappedData(selectedDocuments.listADocumentFields.secondary, 'LIST_A_SECONDARY'),
				tertiary: getMappedData(selectedDocuments.listADocumentFields.tertiary, 'LIST_A_TERTIARY')
			},
			listBPrefilledDocument: getMappedData(selectedDocuments.listBDocumentFields, 'LIST_B'),
			listCPrefilledDocument: getMappedData(selectedDocuments.listCDocumentFields, 'LIST_C')
		});
		setFormData({
			representative_title: employer.title_of_employer_or_authorized_rep,
			representative_city_name: employer.city,
			representative_street_address: employer.address,
			representative_apartment_number: employer.apartment_number,
			representative_zip_code: employer.zip,
			representative_state: employer.state,
			representative_first_name: cgAuthRepDetails.auth_rep_first_name,
			representative_last_name: cgAuthRepDetails.auth_rep_last_name,
			caregiver_first_name: cgAuthRepDetails.caregiver_first_name,
			caregiver_last_name: cgAuthRepDetails.caregiver_last_name
		});
	}, []);

	const fetchData = useCallback(async () => {
		setShowLoadingSpinner(true);
		try {
			const response = await xhr.request('GET', '/i9/section-2/authorized-rep-document-lists', token);
			setDefaultData(response.data);
			setShowLoadingSpinner(false);
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || 'Failed to load I9 data. Try refresh!'));
			setShowLoadingSpinner(false);
		}
	}, [dispatch, setDefaultData, token]);

	const checkFormDataValid = () => {
		const _errors = {};
		let _hasErrors = false;
		Object.keys(initialFormData).forEach((key) => {
			if (!formData[key]) {
				_errors[key] = true;
				_hasErrors = true;
			}
		});

		if (formData.representative_zip_code && formData.representative_zip_code.length < 5) {
			_errors.representative_zip_code = true;
			_hasErrors = true;
		}
		setErrors(_errors);
		setHasErrors(_hasErrors);

		return !_hasErrors;
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (agreeChecked) {
			checkFormDataValid();
		} else if (hasErrors) {
			setErrors({});
		}
	}, [formData]);

	const handleChange = (e) => {
		const key = e.target.name;
		const { value } = e.target;
		if (key) {
			setFormData({ ...formData, [key]: value });
			if (agreeChecked) {
				checkFormDataValid();
			}
		}
	};

	const onSelectList = (list) => {
		setSelectedList(list);
		setSectionsReviewed(defaultSectionsReviewedState);
		setAgreeChecked(false);
	};

	const processResponseErrors = (responseErrors) => {
		const _errors = {};
		// eslint-disable-next-line no-restricted-syntax
		for (const error of responseErrors) {
			if (error.field) {
				if (error.field === 'name') {
					_errors.representative_first_name = true;
					_errors.representative_last_name = true;
				} else {
					_errors[`representative_${error.field}`] = true;
				}
			}
		}
		setErrors(_errors);
	};

	const handleSetSectionsReviewed = (section) => {
		setSectionsReviewed({ ...sectionsReviewed, ...section });
		setAgreeChecked(false);
	};

	const onAgree = () => {
		if (checkFormDataValid()) {
			setShowConfirmationModal(true);
		} else {
			dispatch(showNotification('error', 'You must fill all the required fields with valid data!'));
		}
	};

	const onSubmit = async () => {
		setShowLoadingSpinner(true);
		try {
			await xhr.request('POST', `/i9/section-2`, token, formData);
			setShowSuccessModal(true);
			setShowLoadingSpinner(false);
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || 'Failed to submit I9 data. Please try again!'));
			processResponseErrors(error.response.data.errors);
			setShowLoadingSpinner(false);
		}
	};

	const handleConfirm = () => {
		setShowConfirmationModal(false);
		onSubmit();
	};

	const sectionsReviewCompleted =
		selectedList === 'A'
			? sectionsReviewed.sectionA1 &&
			  (sectionsReviewed.sectionA2 || !formData.list_a_secondary_document_type) &&
			  (sectionsReviewed.sectionA3 || !formData.list_a_tertiary_document_type)
			: sectionsReviewed.sectionB && sectionsReviewed.sectionC;

	const isSignatureDisabled = !agreeChecked || !sectionsReviewCompleted;

	return (
		<div className="section2-wrapper">
			<div className="section2">
				<div className="title title-small">Please review the following</div>
				<div className="text">
					<p>Please read instructions carefully before completing this form.</p>
					<p>
						Verify the information below by physically examining the original documents presented by the employee to
						ensure they reasonably appear genuine and relate to the person. You must also verify that the information
						from the original documents presented matches the information provided in I-9 section 2 and, if not, update
						accordingly.
					</p>
					<p>
						If the employee presents different document(s) than those preliminarily identified, please use the drop-down
						menu to select the correct one document from List A or correct one from List B and one document from List C.
						Then, enter the respective document number(s) and expiration date(s).&nbsp;
						<a
							href="https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Click here
						</a>
						&nbsp;to view detailed instructions (page 6).
					</p>
				</div>
				<div className="title">USCIS Form I-9</div>
				<div className="heading">Section 2. Employer or Authorized Representative Review and Verification</div>
				<div className="subtitle">PLEASE COMPLETE THE FOLLOWING</div>

				<div className="text">
					<a
						href="https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents"
						target="_blank"
						rel="noopener noreferrer"
					>
						Click here
					</a>
					&nbsp;to find a list of Acceptable Documents.
				</div>
				<div className="text">
					All documents must be UNEXPIRED. Present one document from List A or a combination of one document from both
					List B and List C.
				</div>
				<div className="heading">
					Select the list with {`${formData.caregiver_first_name}'s`} identity and/or employment authorization documents
				</div>

				<div className="list-buttons-container">
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
					<div
						className={classNames('list-button', { selected: selectedList === 'A' })}
						onClick={() => onSelectList('A')}
						role="button"
					>
						List A
					</div>
					<div className="space-between" />
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
					<div
						className={classNames('list-button button-blue', { selected: selectedList === 'BC' })}
						onClick={() => onSelectList('BC')}
						role="button"
					>
						List B and C
					</div>
				</div>

				<div className="form">
					{selectedList === 'A' ? (
						<SectionA
							token={token}
							listA={fetchedListData.list_a}
							listAPrefilledDocuments={fetchedListData.listAPrefilledDocuments}
							setSectionsReviewed={handleSetSectionsReviewed}
							showLoadingSpinner={setShowLoadingSpinner}
							caregiver_first_name={formData.caregiver_first_name}
						/>
					) : (
						<SectionBC
							token={token}
							listB={fetchedListData.list_b}
							listC={fetchedListData.list_c}
							listBPrefilledDocument={fetchedListData.listBPrefilledDocument}
							listCPrefilledDocument={fetchedListData.listCPrefilledDocument}
							setSectionsReviewed={handleSetSectionsReviewed}
							showLoadingSpinner={setShowLoadingSpinner}
							caregiver_first_name={formData.caregiver_first_name}
						/>
					)}
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...textFieldDefaults}
						className="first-name"
						label="First Name of Employer or Authorized Representative*"
						name="representative_first_name"
						value={formData.representative_first_name}
						onChange={handleChange}
						error={errors.representative_first_name}
					/>
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...textFieldDefaults}
						label="Last Name of Employer or Authorized Representative*"
						name="representative_last_name"
						value={formData.representative_last_name}
						onChange={handleChange}
						error={errors.representative_last_name}
					/>
					<TextField
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...readOnlyTextFieldDefaults}
						label="Title of Employer or Authorized Representative*"
						name="representative_title"
						value={formData.representative_title}
						onChange={handleChange}
						error={errors.representative_title}
					/>

					{/* eslint-disable-next-line react/no-unescaped-entities */}
					<div className="heading-small">Employer's Business or Organization Address</div>
					<div className="representative-box">
						<p>{formData.representative_street_address}</p>
						<p>{formData.representative_apartment_number}</p>
						<p>{`${formData.representative_city_name} ${formData.representative_state} ${formData.representative_zip_code}`}</p>
					</div>

					<div className="agree-acknowledgment">
						<label htmlFor="checkbox">
							<input
								id="checkbox"
								type="checkbox"
								className="agree-checkbox"
								onChange={() => setAgreeChecked(!agreeChecked)}
								value={agreeChecked}
								checked={agreeChecked}
								disabled={!sectionsReviewCompleted}
							/>
						</label>
						<p className="agree-text">
							I attest, under penalty of perjury, that (1) I have examined the document(s) presented by the above-named
							employee, (2) the above-listed document(s) appear to be genuine and to relate to the employee named, and
							(3) to the best of my knowledge the employee is authorized to work in the United States.
						</p>
					</div>

					<p className="text-pre-signature">
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						Click "Sign Form I-9 Electronically" to complete the electronic signature
					</p>
				</div>
			</div>

			<Button onClick={onAgree} disabled={isSignatureDisabled} className="sign-button">
				SIGN FORM I-9 ELECTRONICALLY
			</Button>

			{showConfirmationModal && (
				<ConfirmationModal
					title="Confirmation"
					onConfirm={handleConfirm}
					onCancel={() => setShowConfirmationModal(false)}
					okText="YES"
					cancelText="NO"
				>
					<div>
						<p>
							You are signing Section 2 of the Form I-9. You must verify your submission before signing electronically.
							You will not be able to return to this form after it has been electronically signed. Click CONFIRM if you
							are ready to continue with completing the electronic signature.
						</p>
					</div>
				</ConfirmationModal>
			)}

			{showSuccessModal && (
				<SuccessModal buttonLabel="DISMISS" onConfirm={() => window.close()}>
					<div className="message">Your input data was submitted successfully.</div>
				</SuccessModal>
			)}

			{showLoadingSpinner && (
				<div className="spinner-center-wrapper">
					<LoadingSpinner className="saving-spinner" />
				</div>
			)}
		</div>
	);
};

export default withRouter(Section2);
