import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import successIcon from '../../assets/images/success.svg';

import './SuccessModal.css';

const SuccessModal = (props) => {
	return (
		<Modal
			size={props.size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="success-modal"
		>
			<Modal.Body className={props.modalBodyClass}>
				<div className="success-image-container">
					<img src={successIcon} alt="Success" />
				</div>
				{props.children}
				<Button onClick={props.onConfirm} className="dismiss-button">
					{props.buttonLabel}
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default SuccessModal;
