import React from 'react';
import classNames from 'classnames';

const Button = (props) => (
	<button
		className={classNames(
			'button',
			{
				'button--xs': props.size === 'xs',
				'button--sm': props.size === 'sm',
				'disabled-button': props.disabled
			},
			props.className
		)}
		/* eslint-disable-next-line react/button-has-type */
		type={props.type ? props.type : 'button'}
		disabled={props.disabled}
		onClick={props.onClick}
	>
		{props.children}
	</button>
);

export default Button;
