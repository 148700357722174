import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from "../spinner/LoadingSpinner";
import './ConfirmationModal.css';

const ConfirmationModal = (props) => {
	const showCancel = props.showCancel !== undefined ? props.showCancel : true;

	return (
		<Modal
			size={props.size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="confirmation-modal"
			onHide={props.onCancel}
		>
			<Modal.Header closeButton>
				<Modal.Title className="modal-title">{props.title || 'Confirm'}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={props.customModalBodyClass}>
				{!props.showLoadingSpinner ? (
					props.children
				) : (
					<div className="spinner-wrapper">
						<LoadingSpinner key="loading-spinner" />
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{showCancel && (
					<Button onClick={props.onCancel} variant="secondary">
						{props.cancelLabel || 'CANCEL'}
					</Button>
				)}
				<Button onClick={props.onConfirm} variant="primary" disabled={props.showLoadingSpinner}>
					{props.confirmLabel || 'CONFIRM'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmationModal;
