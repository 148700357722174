import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import MaterialUISelect from '../../components/form/MaterialUISelect';
import { showNotification } from '../../redux/notification';
import Button from '../../components/buttons/Button';
import uploadIcon from '../../assets/images/upload-red-icon.svg';
import checkIcon from '../../assets/images/tick-green.svg';

const textFieldDefaults = {
	type: 'text',
	inputProps: { style: { fontSize: 14, color: '#000' } },
	InputLabelProps: { style: { fontSize: 14, color: '#717579' } },
	autoComplete: 'off'
};

const DocumentSection = (props) => {
	const dispatch = useDispatch();

	const fileInputRef = useRef(null);

	const [errors, setErrors] = useState({});
	const [type, setType] = useState('');
	const [authority, setAuthority] = useState('');
	const [number, setNumber] = useState('');
	const [date, setDate] = useState('');
	const [attachment, setAttachment] = useState(null);
	const [attachmentIsPrefilled, setAttachmentIsPrefilled] = useState(null);
	const [isPrefilled, setIsPrefilled] = useState(false);
	const [sectionReviewed, setSectionReviewed] = useState(false);

	const {
		sectionId,
		onSelectDocument,
		onSelectAuthority,
		onChangeNumber,
		onChangeDate,
		onChangeAttachment,
		onChangeAttachmentId,
		prefilledDocument,
		documentOptions,
		onSetSectionReviewed
	} = props;

	const formFieldDisabled = type === 'N/A';

	const updateSectionReviewed = async (reviewed) => {
		const isReviewed = await onSetSectionReviewed(reviewed);
		setSectionReviewed(isReviewed);
	};

	useEffect(() => {
		setType('');
		setAuthority('');
		setNumber('');
		setDate('');
		setAttachment(null);
		setAttachmentIsPrefilled(null);
		updateSectionReviewed(false);
	}, [documentOptions]);

	useEffect(() => {
		if (props.authorityOptions && props.authorityOptions.length === 1 && props.authorityOptions[0].label === 'N/A') {
			if (!authority) {
				setAuthority(props.authorityOptions[0].value);
			}
		}
	}, [props.authorityOptions]);

	const onChangeType = useCallback(
		(e) => {
			const newValue = e.target.value;
			setType(newValue);
			onSelectDocument(newValue);

			if (prefilledDocument && newValue === prefilledDocument.type) {
				setAuthority(prefilledDocument.authority || '');
				onSelectAuthority(prefilledDocument.authority);
				setNumber(prefilledDocument.number || '');
				onChangeNumber(prefilledDocument.number);
				setDate(prefilledDocument.date || '');
				onChangeDate(prefilledDocument.date);
				setIsPrefilled(true);
				setAttachmentIsPrefilled(!!prefilledDocument.attachmentIsPrefilled);
				onChangeAttachmentId(prefilledDocument.attachmentIsPrefilled, prefilledDocument.attachmentHmac);
			} else {
				setAuthority('');
				setNumber('');
				setDate('');
				onSelectAuthority();
				onChangeNumber();
				onChangeDate();
				setIsPrefilled(false);
				setAttachmentIsPrefilled(false);
				onChangeAttachmentId(null, null);
			}
			setAttachment(null);
			onChangeAttachment(null);
			updateSectionReviewed(false);
			fileInputRef.current.value = '';
			setErrors({ ...errors, type: false });
		},
		[setType, errors, onSelectDocument]
	);

	const onChangeAuthority = useCallback(
		(e) => {
			const newValue = e.target.value;
			setAuthority(newValue);
			onSelectAuthority(newValue);
			updateSectionReviewed(false);
			setErrors({ ...errors, authority: false });
		},
		[setAuthority, errors, onSelectAuthority]
	);

	const onChangeDocumentNumber = useCallback(
		(e) => {
			const newValue = e.target.value;
			setNumber(newValue);
			onChangeNumber(newValue);
			updateSectionReviewed(false);
			setErrors({ ...errors, number: false });
		},
		[setNumber, errors, onChangeNumber]
	);

	const onChangeExpirationDate = useCallback(
		(e) => {
			const newValue = e.target.value;
			setDate(newValue);
			onChangeDate(newValue);
			updateSectionReviewed(false);
		},
		[setDate, errors, onChangeDate]
	);

	const checkDate = () => {
		if (date && moment(date).isBefore(moment())) {
			dispatch(showNotification('error', 'The expiration date must be in the future!'));
			setDate('');
		}
		setErrors({ ...errors, date: false });
	};

	const checkValidData = () => {
		const _errors = {};
		let hasErrors = false;
		if (type) {
			const document = props.documentOptions.find((doc) => doc.value === type);
			if (document.is_issuing_authority_required && !authority) {
				_errors.authority = true;
				hasErrors = true;
			}
			if (document.is_document_number_required && !number) {
				_errors.number = true;
				hasErrors = true;
			}
			if (document.is_expiration_date_required && !date) {
				_errors.date = true;
				hasErrors = true;
			}
		} else {
			_errors.type = true;
			hasErrors = true;
		}
		setErrors(_errors);
		return !hasErrors;
	};

	const toggleCheckbox = () => {
		if (sectionReviewed) {
			updateSectionReviewed(false);
		} else if (checkValidData()) {
			if (attachment || attachmentIsPrefilled || formFieldDisabled) {
				updateSectionReviewed(true);
			} else {
				dispatch(
					showNotification('error', 'Please upload a PDF/file of the back and front of the document before continuing.')
				);
			}
		}
	};

	const uploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const onChangeFile = (event) => {
		event.stopPropagation();
		event.preventDefault();
		const file = event.target.files[0];

		if (file) {
			updateSectionReviewed(false);

			if (file.type !== 'application/pdf') {
				dispatch(showNotification('error', 'Invalid file type. Only PDF files are allowed!'));
				return;
			}
			const reader = new FileReader();
			reader.readAsBinaryString(file);
			reader.onload = () => {
				const b64 = btoa(reader.result);
				setAttachment(b64);
				onChangeAttachment(b64);
				setAttachmentIsPrefilled(false);
				onChangeAttachmentId(null, null);
			};
			reader.onerror = () => {
				dispatch(showNotification('error', 'Error uploading document, Please try again.'));
			};
		}
	};

	return (
		<div className={classNames('document-section', { 'review-warning': isPrefilled && !sectionReviewed })}>
			<div className="heading-small">{props.title}</div>
			<MaterialUISelect
				label="Select a document"
				value={type}
				onChange={onChangeType}
				error={props.errors.type || errors.type}
				options={props.documentOptions}
			/>
			{props.freeFormDocumentTypes.includes(type) ? (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...textFieldDefaults}
					label="Issuing Authority"
					value={authority}
					error={props.errors.authority || errors.authority}
					disabled={formFieldDisabled}
					onChange={onChangeAuthority}
				/>
			) : (
				<MaterialUISelect
					label="Issuing Authority"
					value={authority}
					onChange={onChangeAuthority}
					error={props.errors.authority || errors.authority}
					disabled={formFieldDisabled}
					options={props.authorityOptions}
				/>
			)}
			<TextField
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...textFieldDefaults}
				label="Document Number"
				value={number}
				error={props.errors.number || errors.number}
				disabled={formFieldDisabled}
				onChange={onChangeDocumentNumber}
			/>
			{props.noExpirationDateDocuments.includes(type) ? (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<TextField {...textFieldDefaults} label="Expiration Date (if any)" value="N/A" onChange={() => {}} />
			) : (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...textFieldDefaults}
					className="date-field"
					label="Expiration Date (if any)"
					type="date"
					value={date}
					error={props.errors.date || errors.date}
					disabled={formFieldDisabled}
					onChange={onChangeExpirationDate}
					placeholder="yyyy-mm-dd"
					onBlur={checkDate}
				/>
			)}

			{attachment || attachmentIsPrefilled ? (
				<Button className="upload-button upload-completed-button" disabled={formFieldDisabled} onClick={uploadClick}>
					<img src={checkIcon} alt="" />
					<span>UPLOADED</span>
				</Button>
			) : (
				<>
					<Button className="upload-button" disabled={formFieldDisabled} onClick={uploadClick}>
						<img src={uploadIcon} alt="" />
						<span>UPLOAD DOCUMENT</span>
					</Button>
					<div className="upload-note">
						<sup>*</sup>Please upload a PDF file with both sides of the document
					</div>
				</>
			)}
			<input type="file" accept=".pdf" ref={fileInputRef} style={{ display: 'none' }} onChange={onChangeFile} />

			<div className="review-acknowledgment">
				<label htmlFor={`agree-checkbox-id-${sectionId}`}>
					<input
						type="checkbox"
						checked={sectionReviewed}
						onChange={toggleCheckbox}
						className="agree-checkbox"
						id={`agree-checkbox-id-${sectionId}`}
					/>
				</label>
				<p className="agree-text">
					Upon review of the original documentation, click to confirm all information included in List A or B/C is
					accurate.
				</p>
			</div>
		</div>
	);
};

export default memo(DocumentSection);
