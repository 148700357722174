import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'notification',
	initialState: {
		type: '',
		message: ''
	},
    // added to get rid of state.smth = assigned; eslint error
	/* eslint-disable no-param-reassign */
	reducers: {
		setType: (state, action) => {
			state.type = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		hideNotification: (state) => {
			state.type = '';
			state.message = '';
		}
	}
	/* eslint-enable no-param-reassign */
});

export const { hideNotification } = slice.actions;

export const showNotification = (type, message) => (dispatch) => {
	dispatch(slice.actions.setType(type));
	dispatch(slice.actions.setMessage(message));
};

export const notification = (state) => state.notification;

export default slice.reducer;
