import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import config from './config';
import Notification from './components/notification/Notification';
import NotFound from './features/error/NotFound';
import Section2 from './features/section2/Section2';
import I9Decline from './features/section2/I9Decline';

function App() {
	return (
		<div className={classNames('App', { 'ie-10': config.IS_IE })}>
			<Notification />
			<Switch>
				<Route path="/i9/review/:token" exact component={Section2} />
				<Route path="/i9/decline/:token" exact component={I9Decline} />
				<Route path="/404" render={() => <NotFound />} />
				<Route path="/" render={() => <Redirect to="/404" />} />
			</Switch>
		</div>
	);
}

export default App;
