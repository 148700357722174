import axios from 'axios';

import config from "../config";

const xhr = {
	request: async (method, url, token, data, options = {}) => {
		try {
			return await axios.request({
				url: config.API_URL + url,
				method: method.toLowerCase(),
				data,
				headers: {
					Accept: 'application/json',
					'x-authorized-representative-token': token
				},
				...options
			});
		} catch (error) {
			return xhr.handleGenericErrors(error);
		}
	},

	handleGenericErrors(error) {
		const status = error.response && error.response.status;

		if (status === 401 || status === 403 || status === 404) {
			window.location.href = '/404';
			throw error;
		} else {
			throw error;
		}
	}
};

export default xhr;
