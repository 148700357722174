import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import xhr from '../../utils/xhr';
import { showNotification } from '../../redux/notification';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import Button from '../../components/buttons/Button';
import shiftmedIcon from '../../assets/images/shiftmed.svg';

import './I9Decline.css';

const I9Decline = (props) => {
	const dispatch = useDispatch();
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const [success, setSuccess] = useState(false);

	const callDecline = useCallback(async () => {
		const {token} = props.match.params;
		setShowLoadingSpinner(true);
		try {
			await xhr.request('POST', '/i9/decline', token);
			setSuccess(true);
			setShowLoadingSpinner(false);
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || 'Failed to handle decline. Please refresh!'));
			setShowLoadingSpinner(false);
		}
	}, [dispatch, props]);

	useEffect(() => {
		callDecline();
	}, [callDecline]);

	return [
		success ? (
			<div className="decline-screen" key="decline-screen">
				<div className="logo-container">
					<img src={shiftmedIcon} alt="" />
				</div>
				<span className="message">Thank you for letting us know.</span>
				<Button className="dismiss-button" onClick={() => window.close()}>
					DISMISS
				</Button>
			</div>
		) : (
			<div key="white-screen" />
		),

		showLoadingSpinner && (
			<div className="spinner-center-wrapper" key="spinner">
				<LoadingSpinner className="saving-spinner" />
			</div>
		)
	];
};

export default I9Decline;
