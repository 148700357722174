import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { notification, hideNotification } from '../../redux/notification';
import errorIcon from '../../assets/images/error-icon.svg';
import successIcon from '../../assets/images/check-icon.svg';
import infoIcon from '../../assets/images/info-icon.svg';
import closeIcon from '../../assets/images/close-light.svg';
import './Notification.css';

const NOTIFICATION_DISPLAY_TIME = 7000;
let timeout;

function Notification() {
	const { type, message } = useSelector(notification);
	const dispatch = useDispatch();

	const closeNotification = useCallback(() => {
		dispatch(hideNotification());

		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	}, [dispatch]);

	useEffect(() => {
		if (message) {
			clearTimeout(timeout);
			timeout = setTimeout(closeNotification, NOTIFICATION_DISPLAY_TIME);
		}
	}, [message, closeNotification]);

	const getNotificationIcon = (messageType) => {
		switch (messageType) {
			case 'error':
				return errorIcon;
			case 'success':
				return successIcon;
			case 'info':
				return infoIcon;
			default:
				return null;
		}
	};

	return (
		<div className={classNames(`notification notification--${type}`, { 'show-notification': !!message })}>
			<span className="notification__content">
				<img className="notification__icon" alt={type} src={getNotificationIcon(type)} />
				<span className="notification__message">
					{message.constructor === Array ? (
						message.map((msg, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<span key={`error-message-${  i}`}>
								{msg}
								<br />
							</span>
						))
					) : (
						// eslint-disable-next-line react/no-danger
						<div dangerouslySetInnerHTML={{ __html: message }} />
					)}
				</span>
			</span>
			<button type="button" className="clear-button notification__close-button" onClick={closeNotification}>
				<img alt="Close Notification" src={closeIcon} />
			</button>
		</div>
	);
}

export default Notification;
