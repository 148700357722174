import React from 'react';

import './NotFound.css';

const NotFound = () => (
	<div className="not-found-screen">
		<div className="not-found-container flex-one">
			<div className="title">404</div>
			{/* eslint-disable-next-line react/no-unescaped-entities */}
			<div className="subtitle">Sorry, this page isn't available</div>
			<div className="text">The link you followed may be broken or the page may have been removed.</div>
		</div>
	</div>
);

export default NotFound;
