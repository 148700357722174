import React, { useState, useEffect, memo, useCallback, useReducer } from 'react';
import DocumentSection from './DocumentSection';
import xhr from '../../utils/xhr';
import { showNotification } from '../../redux/notification';

const LIST_B_FREE_FORM_DOCUMENT_TYPES = [
	'GOVERNMENT_ID_CARD',
	'SCHOOL_ID_CARD',
	'VOTER_REGISTRATION_CARD',
	'US_MILITARY_CARD',
	'US_MILITARY_DRAFT_RECORD',
	'MILITARY_DEPENDENT_ID_CARD',
	'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
	'SCHOOL_RECORD_UNDER_18',
	'REPORT_CARD_UNDER_18',
	'CLINIC_RECORD_UNDER_18',
	'DOCTOR_RECORD_UNDER_18',
	'HOSPITAL_RECORD_UNDER_18',
	'DAY_CARE_RECORD_UNDER_18',
	'NURSERY_SCHOOL_RECORD_UNDER_18',
	'INDIVIDUAL_UNDER_18',
	'SPECIAL_PLACEMENT',
	'RECEIPT_REPLACEMENT_GOVERNMENT_ID_CARD',
	'RECEIPT_REPLACEMENT_SCHOOL_ID_CARD',
	'RECEIPT_REPLACEMENT_VOTER_REG_CARD',
	'RECEIPT_REPLACEMENT_USA_MILITARY_CARD',
	'RECEIPT_REPLACEMENT_MILITARY_DRAFT_RECORD',
	'RECEIPT_REPLACEMENT_MILITARY_DEP_CARD',
	'RECEIPT_REPLACEMENT_NATIVE_AMERICAN_TRIBAL_DOCUMENT',
	'RECEIPT_REPLACEMENT_SCHOOL_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_REPORT_CARD_UNDER_18',
	'RECEIPT_REPLACEMENT_CLINIC_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_DOCTOR_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_HOSPITAL_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_DAY_CARE_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_NURSERY_SCHOOL_RECORD_RECORD_UNDER_18'
];

const LIST_C_FREE_FORM_DOCUMENT_TYPES = [
	'US_BIRTH_CERTIFICATE',
	'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
	'RECIPIENT_REPLACEMENT_US_BIRTH_CERTIFICATE',
	'RECIPIENT_REPLACEMENT_NATIVE_AMERICAN_TRIBAL_DOCUMENT',
	'RECIPIENT_REPLACEMENT_EMPLOYMENT_AUTH_DOC_DHS',
	'FORM_FS_240'
];

const LIST_B_NO_EXPIRATION_DOCUMENT_TYPES = [
	'US_MILITARY_DRAFT_RECORD',
	'SCHOOL_RECORD_UNDER_18',
	'REPORT_CARD_UNDER_18',
	'CLINIC_RECORD_UNDER_18',
	'DOCTOR_RECORD_UNDER_18',
	'HOSPITAL_RECORD_UNDER_18',
	'DAY_CARE_RECORD_UNDER_18',
	'NURSERY_SCHOOL_RECORD_UNDER_18',
	'INDIVIDUAL_UNDER_18',
	'RECEIPT_REPLACEMENT_MILITARY_DRAFT_RECORD',
	'RECEIPT_REPLACEMENT_SCHOOL_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_REPORT_CARD_UNDER_18',
	'RECEIPT_REPLACEMENT_CLINIC_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_DOCTOR_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_HOSPITAL_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_DAY_CARE_RECORD_UNDER_18',
	'RECEIPT_REPLACEMENT_NURSERY_SCHOOL_RECORD_RECORD_UNDER_18'
];

const LIST_C_NO_EXPIRATION_DOCUMENT_TYPES = [
	'SOCIAL_SECURITY_CARD_UNRESTRICTED',
	'RECIPIENT_REPLACEMENT_SS_CARD_UNRESTRICTED',
	'US_BIRTH_CERTIFICATE',
	'RECIPIENT_REPLACEMENT_US_BIRTH_CERTIFICATE',
	'FORM_FS_545',
	'FORM_DS_1350',
	'FORM_FS_240',
	'FORM_I_197',
	'FORM_I_179'
];

const initialFormData = {
	list_b_document_type: '',
	list_b_document_issuing_authority: '',
	list_b_document_number: '',
	list_b_document_expiration_date: '',
	list_b_attachment: null,
	list_b_attachment_id: null,

	list_c_document_type: '',
	list_c_document_issuing_authority: '',
	list_c_document_number: '',
	list_c_document_expiration_date: '',
	list_c_attachment: null,
	list_c_attachment_id: null
};

function formDataReducer(state, action) {
	switch (action.type) {
		case 'UPDATE':
			// eslint-disable-next-line no-case-declarations
			const { value } = action;
			return { ...state, ...value };
		case 'RESET_DATA':
			return { ...state, ...initialFormData };
		default:
			return state;
	}
}

const SectionBC = (props) => {
	const [formData, dispatch] = useReducer(formDataReducer, initialFormData);
	const [errors, setErrors] = useState({ listB: {}, listC: {} });
	const [listBOptions, setListBOptions] = useState([]);
	const [listBAuthorityOptions, setListBAuthorityOptions] = useState([]);
	const [listCOptions, setListCOptions] = useState([]);
	const [listCAuthorityOptions, setListCAuthorityOptions] = useState([]);

	useEffect(() => {
		setListBOptions(
			props.listB.map((item) => ({
				label: item.document_name,
				value: item.document_code,
				is_issuing_authority_required: item.is_issuing_authority_required,
				is_document_number_required: item.is_document_number_required,
				is_expiration_date_required: item.is_expiration_date_required
			}))
		);
	}, [props.listB]);

	useEffect(() => {
		setListCOptions(
			props.listC.map((item) => ({
				label: item.document_name,
				value: item.document_code,
				is_issuing_authority_required: item.is_issuing_authority_required,
				is_document_number_required: item.is_document_number_required,
				is_expiration_date_required: item.is_expiration_date_required
			}))
		);
	}, [props.listC]);

	const updateFormData = useCallback(
		(newData) => {
			if (typeof newData === 'object' && newData !== null) {
				// eslint-disable-next-line no-restricted-syntax
				for (const key of Object.keys(newData)) {
					// eslint-disable-next-line no-param-reassign
					newData[key] =
						typeof newData[key] === 'string' || newData[key] instanceof String ? newData[key].trim() : newData[key];
				}
			}
			dispatch({ type: 'UPDATE', value: newData });
		},
		[dispatch, formData]
	);

	const selectDocumentBType = (type) => {
		updateFormData({ list_b_document_type: type });
		const document = props.listB.find((doc) => doc.document_code === type);
		setListBAuthorityOptions(
			(document.issuing_authority || []).map((item) => ({ label: item.name, value: item.code }))
		);
	};

	const selectDocumentCType = (type) => {
		const document = props.listC.find((doc) => doc.document_code === type);
		setListCAuthorityOptions(
			(document.issuing_authority || []).map((item) => ({ label: item.name, value: item.code }))
		);
		updateFormData({ list_c_document_type: type });
	};

	const processResponseErrors = (responseErrors) => {
		const message = responseErrors && responseErrors[0] && responseErrors[0].message;
		dispatch(showNotification('error', message || 'Failed to submit I9 data. Please try again!'));

		const _errors = { listB: {}, listC: {} };
		// eslint-disable-next-line no-restricted-syntax
		for (const error of responseErrors) {
			if (error.field) {
				const errorFieldKey = error.field.slice(error.field.lastIndexOf('_') + 1);
				if (error.field.startsWith('list_b_')) {
					_errors.listB[errorFieldKey] = true;
				} else if (error.field.startsWith('list_c_')) {
					_errors.listC[errorFieldKey] = true;
				}
			}
		}
		setErrors(_errors);
	};

	const onSectionBReview = async (reviewed) => {
		props.setSectionsReviewed({ sectionB: reviewed });
		if (reviewed) {
			try {
				props.showLoadingSpinner(true);
				await xhr.request('POST', `/i9/section-2/list-b`, props.token, formData);
				props.showLoadingSpinner(false);
				return true;
			} catch (error) {
				processResponseErrors(error.response.data.errors);
				props.showLoadingSpinner(false);
				return false;
			}
		}
		return false;
	};

	const onSectionCReview = async (reviewed) => {
		props.setSectionsReviewed({ sectionC: reviewed });
		if (reviewed) {
			try {
				props.showLoadingSpinner(true);
				await xhr.request('POST', `/i9/section-2/list-c`, props.token, formData);
				props.showLoadingSpinner(false);
				return true;
			} catch (error) {
				processResponseErrors(error.response.data.errors);
				props.showLoadingSpinner(false);
				return false;
			}
		}
		return false;
	};

	return (
		<>
			<DocumentSection
				sectionId="b"
				title={`Select ${props.caregiver_first_name}'s document from List B`}
				documentOptions={listBOptions}
				authorityOptions={listBAuthorityOptions}
				prefilledDocument={props.listBPrefilledDocument}
				freeFormDocumentTypes={LIST_B_FREE_FORM_DOCUMENT_TYPES}
				noExpirationDateDocuments={LIST_B_NO_EXPIRATION_DOCUMENT_TYPES}
				onSelectDocument={selectDocumentBType}
				onSelectAuthority={(value) => updateFormData({ list_b_document_issuing_authority: value })}
				onChangeNumber={(value) => updateFormData({ list_b_document_number: value })}
				onChangeDate={(value) => updateFormData({ list_b_document_expiration_date: value })}
				onChangeAttachment={(value) => updateFormData({ list_b_attachment: value })}
				onChangeAttachmentId={(id, hmac) => updateFormData({ list_b_attachment_id: id, list_b_attachment_hmac: hmac })}
				onSetSectionReviewed={onSectionBReview}
				errors={errors.listB}
			/>
			<DocumentSection
				sectionId="c"
				title={`Select ${props.caregiver_first_name}'s document from List C`}
				documentOptions={listCOptions}
				authorityOptions={listCAuthorityOptions}
				prefilledDocument={props.listCPrefilledDocument}
				freeFormDocumentTypes={LIST_C_FREE_FORM_DOCUMENT_TYPES}
				noExpirationDateDocuments={LIST_C_NO_EXPIRATION_DOCUMENT_TYPES}
				onSelectDocument={selectDocumentCType}
				onSelectAuthority={(value) => updateFormData({ list_c_document_issuing_authority: value })}
				onChangeNumber={(value) => updateFormData({ list_c_document_number: value })}
				onChangeDate={(value) => updateFormData({ list_c_document_expiration_date: value })}
				onChangeAttachment={(value) => updateFormData({ list_c_attachment: value })}
				onChangeAttachmentId={(id, hmac) => updateFormData({ list_c_attachment_id: id, list_c_attachment_hmac: hmac })}
				onSetSectionReviewed={onSectionCReview}
				errors={errors.listC}
			/>
		</>
	);
};

export default memo(SectionBC);
